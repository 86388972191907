import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useStore, useSelector, useDispatch, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Typography,
	Card,
	Button,
	Chip,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Grid,
} from '@material-ui/core';
import { useParams } from 'react-router';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

import BullhornIcon from '../icons/BullhornIcon';
import Page from '../page/Page';
import * as PageHeader from '../page/PageHeader';
import Table from '../Table';
import CandidateSyncModal from '../modals/CandidateSyncModal';
import CandidateDeleteModal from '../modals/CandidateDeleteModal';
import { InterestIcon } from '../modals/RateJobClientInterestModal';
import LabeledField from '../form/LabeledField';
import CheckboxInput from '../form/inputs/CheckboxInput';
import CandidateFilters from '../filters/CandidateFilters';
import { SkillChip } from '../../pages/candidates/CandidateBlocks/ViewSkillsBlock';
import CandidateLogoLinks from './CandidateLogoLinks';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as CandidatesAPI from '../../scripts/candidates';
import * as Utility from '../../scripts/utility';
import { useContextStore } from '../../store/ContextStore';

const CandidateMatchCardItem = ({
	candidate: propCandidate,
	job,
	match,
	graphMatch,
	small,
	selected,
	toggleSelected,
	setMenu,
	hideItemDetails,
	index,
}) => {
	const candidate = match?.candidate || propCandidate;
	const dispatch = useDispatch();
	const { role } = useContextStore();

	const { data, user } = useSelector(state => state.site, shallowEqual);
	const isShareable = candidate?.is_sharable;

	const bid = candidate?.vendor_candidate_id;
	const bullhornLink = `https://cls30.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${bid}`;

	if (!candidate) {
		return null;
	}

	if (candidate?.id === 8976 || candidate?.id === '8976') {
		console.log({ candidate, graphMatch, match });
	}
	const myFitRanking = graphMatch?.fit_rankings?.find(x => x?.user?.id === user?.id)?.match_fit_ranking?.id;

	const graphMatchViewers = {};
	graphMatch?.views?.forEach(x => {
		graphMatchViewers[x.viewed_by?.id || x.viewed_by] = x;
	});
	const numGraphMatchViewers = Object.keys(graphMatchViewers).length;

	const graphMatchFitRatings = {};
	(graphMatch?.rankings || graphMatch?.fit_rankings)?.forEach(x => {
		graphMatchFitRatings[x.created_by] = x;
	});
	const numGraphMatchFitRatings = Object.keys(graphMatchFitRatings).length;

	const buildTooltipContent = () => {
		if (match?.published_by_user) {
			const date = new Date(match?.published_at);

			const month = date.getMonth() + 1;
			const day = date.getDate();
			const year = date.getFullYear();

			const formattedDate = `${(month < 10 ? '0' : '') + month}/${day < 10 ? '0' : ''}${day}/${year}`;
			return (
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<p>
						Shared {formattedDate} by {match?.published_by_user?.first_name} {match?.published_by_user?.last_name}
					</p>
					<p style={{ marginTop: -5 }}>{match?.access_level?.id === 3 ? 'Full access' : 'Limited access'}</p>
				</div>
			);
		}
		return <p>{match?.access_level?.id === 3 ? 'Shared with full access' : 'Shared with limited access'}</p>;
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 20,
			}}
		>
			<CheckboxInput
				style={{ marginRight: -15 }}
				color="primary"
				checked={selected}
				// onChange={() => {}}
				onClick={() => {
					toggleSelected();
				}}
			/>
			<div
				style={{
					flex: 1,
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
					{!hideItemDetails ? (
						<div style={{ display: 'flex', alignItems: 'baseline' }}>
							{graphMatch?.overall_match_score !== undefined && (
								<>
									<Typography
										variant="body1"
										style={{ fontSize: 13, fontWeight: 500, marginBottom: 0, color: `var(--teal-100)` }}
									>
										{Math.round(graphMatch?.overall_match_score * 100 || 0) || '0'}%
									</Typography>
									<Typography
										variant="body1"
										style={{ fontSize: 13, marginRight: 3, marginLeft: 3, color: `var(--grey-300)` }}
									>
										({Math.round(graphMatch?.completeness_score_overall * 100 || 0) || '0'}%)
									</Typography>
									<Typography
										variant="body1"
										style={{ fontSize: 13, marginRight: 3, marginLeft: 3, color: `var(--grey-300)` }}
									>
										{' | '}
									</Typography>
								</>
							)}
							{data?.candidateStatusTypes?.find(x => x.id === candidate?.status_type_id)?.label &&
								`${data?.candidateStatusTypes?.find(x => x.id === candidate?.status_type_id)?.label} |`}
							{candidate.candidate_status ? (
								<Chip
									size="small"
									label={Utility.getItemLabel(data.candidateStatuses, candidate?.candidate_status)}
									className={`color-${Utility.getCandidateStatusColor(
										Utility.getItemName(data.candidateStatuses, candidate?.candidate_status, 'id'),
									)}`}
									style={{ margin: 0, marginLeft: '.5rem' }}
								/>
							) : null}{' '}
							<CandidateLogoLinks candidate={candidate} />
						</div>
					) : (
						<div style={{ flex: 1 }} />
					)}
					<div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 0 }}>
						{/* {candidate?.owner?.name ? (
							<Typography variant="body2" style={{ fontStyle: 'italic', fontSize: 11, color: 'var(--grey-400)' }}>
								{candidate?.owner?.name}
							</Typography>
						) : null} */}

						{/* <div>
							{myFitRanking !== undefined && (
								<Tooltip title={`My ranking: ${myFitRanking}/4`}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											padding: '5px 8px 5px 8px',
											backgroundColor: '#EEF6FF',
											fontStyle: 'italic',
											marginRight: 5,
											userSelect: 'none',
											height: 31,
										}}
									>
										<InterestIcon interestLevelId={myFitRanking} color="#465670" size={16} />
										<Icons.ThumbUpOutlined style={{ width: 16 }} />
										<div style={{ width: 5 }} />
									{match?.interest_level?.label} 
									</div>
								</Tooltip>
							)}
						</div> 
						*/}

						{match?.interest_level && (
							<Tooltip title={`Rated: ${match?.interest_level?.label}`}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: '5px 8px 5px 8px',
										backgroundColor: '#EEF6FF',
										fontStyle: 'italic',
										marginRight: 5,
										userSelect: 'none',
										height: 31,
									}}
								>
									<InterestIcon interestLevelId={match?.interest_level?.id} color="#465670" size={16} />
									{/* <Icons.ThumbUpOutlined style={{ width: 16 }} /> */}
									{/* <div style={{ width: 5 }} />
									{match?.interest_level?.label} */}
								</div>
							</Tooltip>
						)}
						{match?.user_viewed_at && (
							<Tooltip title={`Viewed ${moment(match?.user_viewed_at).fromNow()}`}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: '5px 8px 5px 8px',
										backgroundColor: '#EEF6FF',
										fontStyle: 'italic',
										marginRight: 5,
										userSelect: 'none',
									}}
								>
									<Icons.Visibility style={{ width: 16 }} />
									{/* <div style={{ width: 5 }} />
									Viewed */}
								</div>
							</Tooltip>
						)}
						{match?.is_published && (
							<Tooltip title={buildTooltipContent()}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: 5,
										backgroundColor: '#E9FCFB',
										// fontStyle: 'italic',
										fontSize: 10,
										marginRight: 5,
										userSelect: 'none',
									}}
								>
									<Icons.Share style={{ width: 16 }} />
									<div style={{ width: 5 }} />
									{match?.access_level?.id === 3 ? 'Shared' : 'Limited'}
								</div>
							</Tooltip>
						)}
						{match && (
							<Tooltip title="Saved">
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: 5,
										backgroundColor: '#F3F6F9',
										fontStyle: 'italic',
										marginRight: 0,
										userSelect: 'none',
									}}
								>
									<Icons.BookmarkBorder style={{ width: 16 }} />
									{/* <div style={{ width: 5 }} />
									Saved */}
								</div>
							</Tooltip>
						)}

						<div style={{ width: 15 }} />
						{!hideItemDetails && (
							<IconButton
								onClick={ev =>
									setMenu({
										anchor: ev.target,
										candidate,
										job,
										match,
									})
								}
							>
								<Icons.MoreVert color="primary" />
							</IconButton>
						)}
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					<Typography variant="body2" style={{ marginBottom: 2, fontSize: 15, color: 'var(--grey-400)' }}>
						<NavLink to={`/candidate/${candidate?.id}`} className="color-inherit" target="_blank">
							{hideItemDetails ? (
								<>Candidate {index + 1}</>
							) : (
								<>
									{candidate?.first_name} {candidate?.last_name}{' '}
									{candidate?.current_employer ? ` @ ${candidate?.current_employer}` : ''}
								</>
							)}

							{/* | {candidate?.vendor_candidate_id} */}
						</NavLink>
					</Typography>

					{!hideItemDetails && (
						<Typography
							variant="body1"
							style={{ marginBottom: 0, marginLeft: 5, fontSize: 13, color: 'var(--grey-300)' }}
						>
							| Exp. {candidate?.professional_experience_years || 0} yr
							{candidate?.professional_experience_years !== 1 && 's'}
							{candidate?.total_leadership_years
								? ` | Leadership: ${candidate?.total_leadership_years || 0} yr${
										candidate?.total_leadership_years === 1 ? '' : 's'
								  }`
								: ''}
						</Typography>
					)}
					{!small && !hideItemDetails && (
						<Typography
							variant="body1"
							style={{ marginBottom: 0, marginLeft: 20, fontSize: 13, color: 'var(--grey-300)' }}
						>
							Created: {Utility.formatDate(candidate.created_at)} | Updated: {Utility.formatDate(candidate.updated_at)}
						</Typography>
					)}
				</div>
				{!hideItemDetails ? (
					<Typography variant="body1" style={{ marginBottom: 0, fontSize: 13, color: 'var(--grey-300)' }}>
						{candidate?.desired_position || 'Unknown position'} | Salary:{' '}
						{candidate?.salary_expectation_type?.label || 'Unknown'} |{' '}
						{candidate?.city && candidate?.state && `${candidate?.city}, ${candidate?.state}`} |{' '}
						{Utility.getConsolidatedLocationPreferences(candidate?.work_location_preferences).join(' | ') || 'Unknown'}
					</Typography>
				) : (
					<Typography variant="body1" style={{ marginBottom: 0, fontSize: 13, color: 'var(--grey-300)' }}>
						{candidate?.desired_position || 'Unknown position'}
					</Typography>
				)}
				<Grid item xs={12} style={{ marginBottom: 0, paddingTop: 5 }}>
					{(candidate.skills || [])
						.filter(x => x.get_the_job)
						.map(skill => (
							<SkillChip
								baseColor="blue"
								key={skill?.id}
								skill={{
									...skill,
									// required_skill: true,
								}}
							/>
						))}
				</Grid>
				<Grid item xs={12} style={{ marginBottom: -12 }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{graphMatch ? (
							<>
								<Tooltip
									title={
										<div>
											{numGraphMatchViewers > 0
												? Object.values(graphMatchViewers)?.map(x => (
														<p style={{ fontSize: 12, marginTop: 3, marginBottom: 3 }}>
															{x?.viewed_by_user?.first_name || x?.viewed_by?.first_name}{' '}
															{x?.viewed_by_user?.last_name || x?.viewed_by?.last_name}
															<span style={{ marginLeft: 3, fontSize: 11, fontStyle: 'italic', opacity: 0.7 }}>
																{moment(x?.viewed_at).fromNow()}
															</span>
														</p>
												  ))
												: 'None'}
										</div>
									}
								>
									<div style={{ display: 'flex', alignItems: 'center', cursor: 'default', marginTop: 3 }}>
										<Icons.Visibility style={{ width: 12, opacity: 0.3, marginRight: 5 }} />
										<Typography
											variant="body1"
											style={{ fontSize: 13, color: 'var(--grey-300)' }}
										>{`${numGraphMatchViewers} view${numGraphMatchViewers !== 1 ? 's' : ''}`}</Typography>
									</div>
								</Tooltip>
								<div style={{ width: 15 }} />
								<Tooltip
									title={
										<div>
											{numGraphMatchFitRatings > 0
												? Object.values(graphMatchFitRatings)?.map(x => (
														<p style={{ fontSize: 12, marginTop: 3, marginBottom: 3 }}>
															{x?.user?.first_name || x?.created_by_user?.first_name}{' '}
															{x?.user?.last_name || x?.created_by_user?.last_name}:{' '}
															{x?.match_fit_ranking?.label || x?.match_fit_ranking_status?.label}
															<span style={{ marginLeft: 3, fontSize: 11, fontStyle: 'italic', opacity: 0.7 }}>
																{moment(x?.created_at).fromNow()}
															</span>
														</p>
												  ))
												: 'None'}
										</div>
									}
								>
									<div style={{ display: 'flex', alignItems: 'center', cursor: 'default', marginTop: 3 }}>
										<Icons.ThumbUp style={{ width: 12, opacity: 0.3, marginRight: 5 }} />
										<Typography
											variant="body1"
											style={{ fontSize: 13, color: 'var(--grey-300)' }}
										>{`${numGraphMatchFitRatings} ranking${numGraphMatchFitRatings !== 1 ? 's' : ''}`}</Typography>
									</div>
								</Tooltip>
							</>
						) : null}
					</div>
				</Grid>
				{/* <Typography variant="body1" style={{ fontSize: 13, color: 'var(--grey-300)' }}>
					Land the Job Skills:{' '}
					{candidate?.skills
						?.filter(s => s?.get_the_job)
						?.slice(0, 3)
						?.map(x => x?.skill?.label || '-')
						?.join(', ')}
				</Typography> */}
				{/* <Typography variant="body1" style={{ marginBottom: 0, fontSize: 13, color: 'var(--grey-300)' }}>
					Portland, OR | Expected Salary: $170,000 | Exp. 5 years
				</Typography>
				<Typography variant="body1" style={{ fontSize: 13, color: 'var(--grey-300)' }}>
					Hero Skills: Java, DevOps, AWS
				</Typography> */}
			</div>
		</div>
	);
};

export default CandidateMatchCardItem;
