import React, { useCallback, useEffect, useRef, useState, useMemo, forwardRef } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Typography,
	Button,
	Chip,
	IconButton,
	Checkbox,
	Menu,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Grid,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import elasticsearch from 'elasticsearch';

import CheckboxInput from '../../components/form/inputs/CheckboxInput';
import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import Table from '../../components/Table';
import JobDeleteModal from '../../components/modals/JobDeleteModal';
import JobFilters from '../../components/filters/JobFilters';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as Utility from '../../scripts/utility';
import JobSyncModal from '../../components/modals/JobSyncModal';
import { palette } from '../../BobcatTheme';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { isLocalAPI, isStagingAPI } from '../../scripts/api';
import { useContextStore } from '../../store/ContextStore';

const IS_STAGING_OR_LOCAL = isLocalAPI || isStagingAPI;

const Jobs = () => {
	const store = useStore();
	const { data, alert } = useSelector(state => state.site, shallowEqual);

	useDocumentTitle('Jobs | NauMatch');

	const { getCurrentAccessToken } = useContextStore();
	const [gridApi, setGridApi] = useState(null);
	const [gridKey, setGridKey] = useState(0);
	const [menu, setMenu] = useState({ anchor: null, listing: {} });
	const [filter, setFilter] = Utility.useStickyState({ isOpen: false, filters: {} }, 'admin-listings-filter');

	const [activeSaveFilterId, setActiveSaveFilterId] = Utility.useStickyState(null, 'admin-jobs-active-save-filter-id');
	const [savedFilters, setSavedFilters] = Utility.useStickyState([], 'admin-jobs-saved-filtersets');

	const [datasource, setDatasource] = useState({
		getRows: async params => {
			const { startRow, endRow, sortModel, filterModel, successCallback, failCallback, context } = params;

			// console.log({
			// 	filterModel,
			// 	sortModel,
			// 	startRow,
			// 	endRow,
			// });
			let currentFilter = null;
			setFilter(_currentFilter => {
				currentFilter = _currentFilter;
				return _currentFilter;
			});

			const HOST_URL = IS_STAGING_OR_LOCAL
				? 'https://nauwork-portal-elastic-dev.es.westus2.azure.elastic-cloud.com'
				: 'https://nauwork-portal-elastic-prod.es.westus2.azure.elastic-cloud.com/';
			const CLOUD_ID = IS_STAGING_OR_LOCAL
				? // eslint-disable-next-line max-len
				  'nauwork-portal-elastic-dev:d2VzdHVzMi5henVyZS5lbGFzdGljLWNsb3VkLmNvbTo0NDMkZGZjMzA4MWZhMTIzNDJmNDkwZTgyMTJjOGMwNzRmYzEkYzk0OGVhNzEzMzEzNDZiODgxZGZjZDhiMjJiZDNiMjk='
				: // eslint-disable-next-line max-len
				  'nauwork-portal-elastic-prod:d2VzdHVzMi5henVyZS5lbGFzdGljLWNsb3VkLmNvbTo0NDMkZWFiODY3MDM5MWFkNGY2Yjk4MWYxMDNhZWY1MzUxOWMkODEzY2Q5Yzg2OWNkNDkzNjkwNGRlOWNmZTNhNmU5NWU=';

			const query = {
				bool: {
					must: [],
					should: [],
				},
			};

			console.log({ currentFilter });

			if (currentFilter.filters?.position_title?.length) {
				// Split the name
				query.bool.must.push({
					match: {
						position_title: `*${currentFilter.filters?.position_title}*`,
					},
				});
			}
			if (currentFilter.filters?.owner?.length) {
				query.bool.must.push({
					terms: {
						owner: currentFilter.filters?.owner,
					},
				});
			}
			if (currentFilter.filters?.company?.length) {
				query.bool.must.push({
					match: {
						company: currentFilter.filters?.company,
					},
				});
			}
			if (currentFilter.filters?.vendor_job_id?.length) {
				query.bool.must.push({
					match: {
						vendor_job_id: currentFilter.filters?.vendor_job_id,
					},
				});
			}
			if (
				currentFilter.filters?.professional_experience_years_min ||
				currentFilter.filters?.professional_experience_years_max
			) {
				query.bool.must.push({
					range: {
						professional_experience_years: {
							gte: currentFilter.filters?.professional_experience_years_min
								? parseFloat(currentFilter.filters?.professional_experience_years_min)
								: 0,
							lte: currentFilter.filters?.professional_experience_years_max
								? parseFloat(currentFilter.filters?.professional_experience_years_max)
								: 99,
						},
					},
				});
			}

			if (currentFilter.filters?.salary_expectation_type_min || currentFilter.filters?.salary_expectation_type_max) {
				query.bool.must.push({
					range: {
						salary_expectation_type_id: {
							gte: currentFilter.filters?.salary_expectation_type_min
								? parseFloat(currentFilter.filters?.salary_expectation_type_min)
								: 0,
							lte: currentFilter.filters?.salary_expectation_type_max
								? parseFloat(currentFilter.filters?.salary_expectation_type_max)
								: 99,
						},
					},
				});
			}

			if (currentFilter.filters?.location_city?.length) {
				query.bool.must.push({
					term: {
						city: currentFilter.filters?.location_city,
					},
				});
			}

			if (currentFilter.filters?.location_state?.length) {
				query.bool.must.push({
					match: {
						state: currentFilter.filters?.location_state,
					},
				});
			}

			if (currentFilter.filters?.work_location_preferences?.length) {
				query.bool.must.push({
					terms: {
						'work_location_preferences.id': currentFilter.filters?.work_location_preferences,
					},
				});
			}

			// if (currentFilter.filters?.desired_locations?.length) {
			// 	query.bool.must.push({
			// 		terms: {
			// 			'desired_locations.state.id': currentFilter.filters?.desired_locations,
			// 		},
			// 	});
			// }

			if (currentFilter.filters?.description?.length) {
				query.bool.must.push({
					match: {
						description: currentFilter.filters?.description,
					},
				});
			}

			if (currentFilter.filters?.professional_category_id?.length) {
				query.bool.must.push({
					terms: {
						professional_category_id: currentFilter.filters?.professional_category_id,
					},
				});
			}

			if (currentFilter.filters?.specialty_id?.length) {
				query.bool.must.push({
					terms: {
						'specialties.id': currentFilter.filters?.specialty_id,
					},
				});
			}

			if (currentFilter.filters?.skills?.length) {
				const nestedQuery = {
					nested: {
						path: 'candidate_skills',
						query: {
							bool: {
								must: [],
							},
						},
					},
				};
				currentFilter.filters?.skills?.forEach(x =>
					nestedQuery.nested.query.bool.must.push({
						match: { 'candidate_skills.skill.id': x },
					}),
				);
				query.bool.must.push(nestedQuery);
				// query.bool.must.push({
				// 	match: {
				// 		'candidates.first_name': currentFilter.filters?.name,
				// 	},
				// });
			}

			if (currentFilter.filters?.hero_skills?.length) {
				const nestedQuery = {
					nested: {
						path: 'listing_skills',
						query: {
							bool: {
								must: [],
							},
						},
					},
				};

				currentFilter.filters?.hero_skills?.forEach(x =>
					nestedQuery.nested.query.bool.must.push({
						bool: {
							must: [
								{
									match: { 'listing_skills.skill.id': x },
								},
								{
									match: { 'listing_skills.hero_skill': true },
								},
							],
						},
					}),
				);
				query.bool.must.push(nestedQuery);
			}

			if (currentFilter.filters?.industries?.length) {
				query.bool.must.push({
					terms: {
						'industries.id': currentFilter.filters?.industries,
					},
				});
			}

			if (currentFilter.filters?.recent_leadership_role_id?.length) {
				query.bool.must.push({
					terms: {
						recent_leadership_role_id: currentFilter.filters?.recent_leadership_role_id,
					},
				});
			}

			if (currentFilter.filters?.total_leadership_years_min || currentFilter.filters?.total_leadership_years_max) {
				query.bool.must.push({
					range: {
						total_leadership_years: {
							gte: currentFilter.filters?.total_leadership_years_min
								? parseFloat(currentFilter.filters?.total_leadership_years_min)
								: 0,
							lte: currentFilter.filters?.total_leadership_years_max
								? parseFloat(currentFilter.filters?.total_leadership_years_max)
								: 99,
						},
					},
				});
			}

			if (currentFilter.filters?.recent_leadership_years_min || currentFilter.filters?.recent_leadership_years_max) {
				query.bool.must.push({
					range: {
						recent_leadership_role_years: {
							gte: currentFilter.filters?.recent_leadership_years_min
								? parseFloat(currentFilter.filters?.recent_leadership_years_min)
								: 0,
							lte: currentFilter.filters?.recent_leadership_years_max
								? parseFloat(currentFilter.filters?.recent_leadership_years_max)
								: 99,
						},
					},
				});
			}

			if (currentFilter.filters?.role_type?.length) {
				query.bool.must.push({
					bool: {
						should: currentFilter.filters?.role_type?.map(x => ({
							match: {
								role_type: x,
							},
						})),
					},
				});
			}

			// if (currentFilter.filters?.desired_position?.length) {
			// 	query.bool.must.push({
			// 		match: {
			// 			desired_position: currentFilter.filters?.desired_position,
			// 		},
			// 	});
			// }

			if (currentFilter.filters?.current_title?.length) {
				query.bool.must.push({
					match: {
						current_title: currentFilter.filters?.current_title,
					},
				});
			}

			if (currentFilter.filters?.current_employer?.length) {
				query.bool.must.push({
					match: {
						current_employer: currentFilter.filters?.current_employer,
					},
				});
			}

			if (currentFilter.filters?.work_visa_type_id) {
				query.bool.must.push({
					term: {
						work_visa_type_id: currentFilter.filters?.work_visa_type_id,
					},
				});
			}

			if (currentFilter.filters?.status?.length) {
				query.bool.must.push({
					terms: {
						candidate_status: currentFilter.filters?.status,
					},
				});
			}

			// if (currentFilter.filters?.assessment_completed_min || currentFilter.filters?.assessment_completed_max) {
			// 	query.bool.must.push({
			// 		range: {
			// 			assessment_completed_at: {
			// 				gte: currentFilter.filters?.assessment_completed_min
			// 					? currentFilter.filters?.assessment_completed_min
			// 					: '01/01/1970',
			// 				lte: currentFilter.filters?.assessment_completed_max
			// 					? currentFilter.filters?.assessment_completed_max
			// 					: '12/31/2099',
			// 			},
			// 		},
			// 	});
			// }

			// if (currentFilter.filters?.shared_match_count_min || currentFilter.filters?.shared_match_count_max) {
			// 	query.bool.must.push({
			// 		range: {
			// 			shared_match_count: {
			// 				gte: currentFilter.filters?.shared_match_count_min
			// 					? parseFloat(currentFilter.filters?.shared_match_count_min)
			// 					: 0,
			// 				lte: currentFilter.filters?.shared_match_count_max
			// 					? parseFloat(currentFilter.filters?.shared_match_count_max)
			// 					: 99,
			// 			},
			// 		},
			// 	});
			// }

			if (currentFilter.filters.last_profile_update_min) {
				query.bool.must.push({
					range: {
						updated_at: {
							gte: currentFilter.filters.last_profile_update_min,
						},
					},
				});
			}

			if (currentFilter.filters.last_profile_update_max) {
				query.bool.must.push({
					range: {
						updated_at: {
							lte: currentFilter.filters.last_profile_update_max,
						},
					},
				});
			}

			if (currentFilter.filters.assessment_completed_min) {
				query.bool.must.push({
					range: {
						assessment_completed_at: {
							gte: currentFilter.filters.assessment_completed_min,
						},
					},
				});
			}

			if (currentFilter.filters.assessment_completed_max) {
				query.bool.must.push({
					range: {
						assessment_completed_at: {
							lte: currentFilter.filters.assessment_completed_max,
						},
					},
				});
			}

			const currentAccessToken = await getCurrentAccessToken();
			if (currentAccessToken) {
				const config = {
					cloud: {
						id: CLOUD_ID,
					},
					host: HOST_URL, // 'https://nauwork-portal-elastic-dev.es.westus2.azure.elastic-cloud.com',
				};

				const client = new elasticsearch.Client(config);

				client
					.search({
						body: {
							size: endRow - startRow,
							from: startRow,
							sort: [
								{
									[sortModel[0]?.colId?.replace('listings.', '')]: sortModel[0]?.sort, // 'desc',
								},
							],
							query,
						},

						index: 'listings_index',
						headers: { Authorization: `Bearer ${currentAccessToken}` },
					})
					.then(body => {
						// eslint-disable-next-line no-underscore-dangle
						successCallback(
							// eslint-disable-next-line no-underscore-dangle
							body.hits.hits.map(x => x._source),
							body?.hits?.total?.value,
						);
					});
			}

			// setFilter(currentFilter => {
			// 	ListingsAPI.getListings(
			// 		{
			// 			per_page: endRow - startRow,
			// 			page: Math.floor(startRow / (endRow - startRow)) + 1,
			// 			orderBy: sortModel[0]?.colId, // 'candidates.created_at',
			// 			direction: sortModel[0]?.sort, // 'desc',
			// 			...currentFilter.filters,
			// 		},
			// 		results => {
			// 			// console.log(results.data.data);
			// 			successCallback(results.data.data, results.data?.meta?.total);
			// 		},
			// 	);

			// 	return currentFilter;
			// });
		},
	});
	const [selectedItems, setSelectedItems] = useState([]);
	const table = useRef();

	const dataIsLoaded = data?.jobStatuses?.length;

	const numFilters = Object.values(filter.filters || {}).filter(x => x).length;

	const onFilterUpdate = () => {
		if (gridApi) {
			// setGridKey(x => x + 1);
			gridApi.onFilterChanged();
		}
	};

	const filterIntakeCompleted = filter?.filters?.assessment_completed_min && filter?.filters?.assessment_completed_max;
	const setFilterIntakeCompleted = newFilterIntakeCompleted => {
		setFilter(currentFilter => ({
			...currentFilter,
			filters: {
				...currentFilter.filters,
				assessment_completed_min: newFilterIntakeCompleted ? new Date('01/01/1970').toISOString() : undefined,
				assessment_completed_max: newFilterIntakeCompleted ? new Date('12/31/2099').toISOString() : undefined,
			},
		}));

		onFilterUpdate();
	};

	const openFilter = () => {
		setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		setFilter({ ...filter, isOpen: false });
	};

	const updateFilters = useCallback(
		(ev, name) => {
			if (typeof ev === 'object' && typeof name === 'string') {
				setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
			} else if (ev.target.type === 'checkbox') {
				setFilter({
					...filter,
					filters: { ...filter.filters, [ev.target.name]: ev.target.checked ? ev.target.value : undefined },
				});
			} else if (ev.target.value !== null && ev.target.value !== undefined && ev.target.value !== '') {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: ev.target.value } });
			} else {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
			}

			onFilterUpdate();
		},
		[filter, setFilter],
	);

	const sendAssessment = ev => {
		setMenu({ ...menu, anchor: null });

		ListingsAPI.sendAssessment(menu.listing.id, (response, err) => {
			if (!response) {
				let message = 'The job assessment could not be sent. Please try again.';
				if (err === 'contact') message = 'A contact could not be found for this job. The assessment was not sent.';

				store.dispatch(SiteActions.showAlert(message, 'error'));
				return;
			}

			store.dispatch(SiteActions.showAlert('The job assessment was sent.', 'success'));
		});
	};

	const [tableData, setTableData] = useState([]);

	// const mockAssessment = ev => {
	// 	setMenu({ ...menu, anchor: null });
	// 	const auth = JSON.parse(localStorage.getItem('authentication'));
	// 	localStorage.setItem(
	// 		'authentication',
	// 		JSON.stringify({
	// 			...auth,
	// 			listing_id: menu.listing.id,
	// 			role: 'job',
	// 			contact_id: menu.listing.owner.id,
	// 		}),
	// 	);
	// 	const win = window.open(`/job/${menu.listing.id}/profile`, '_blank');
	// 	win.focus();
	// };

	// const headers = [
	// 	{ id: '', value: '', noSort: true },
	// 	{ id: 'select', value: '', noSort: true },
	// 	{ id: 'listings.vendor_job_id', value: 'ID', noSort: false },
	// 	{ id: 'listings.position_title', value: 'Job' },
	// 	{ id: 'specialties.label', value: 'Specialty' },
	// 	{ id: 'owner', value: 'Owner', noSort: true },
	// 	{ id: 'contact', value: 'Contact', noSort: true },
	// 	{ id: 'listings.company', value: 'Company' },
	// 	{ id: 'listings.city', value: 'Work Location', noSort: true },
	// 	{ id: 'hero_skills', value: 'Hero Skills', noSort: true },
	// 	{ id: 'listings.last_profile_update', value: 'Last Updated' },
	// 	{ id: 'listings.assessment_completed_at', value: 'Intake Completed' },
	// 	{ id: 'job_statuses.name', value: 'Job Status', noSort: true },
	// 	{ id: 'shared_match_count', value: 'Shared Matches', noSort: true },
	// ];
	// if (process.env.REACT_APP_FEATURE_MATCHING) {
	// 	headers.push({ id: 'job.matches', value: 'Matches', noSort: true });
	// }

	const defaultColDef = useMemo(() => ({
		sortable: true,
	}));
	const gridRef = useRef(); // Optional - for accessing Grid's API

	const columnDefs = [
		{
			field: 'options',
			fieldName: 'Options',
			// value: '',
			// noSort: true,
			width: 50,
			headerName: '',
			cellRenderer: ({ data: job, node }) => {
				if (!node.id) {
					return null;
				}

				return (
					<IconButton
						key={job?.id}
						style={{}}
						onClick={ev => {
							setMenu({ anchor: ev.currentTarget.parentElement, listing: job });
						}}
					>
						<Icons.MoreVert color="primary" />
					</IconButton>
				);
			},
		},
		{
			field: 'listings.vendor_job_id',
			// fieldName: 'Vendor ID',
			// value: 'ID',
			// noSort: false,
			headerName: 'ID',
			sortable: true,
			width: 100,
			cellRenderer: forwardRef(({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return <span ref={ref}>{job?.vendor_job_id || 'N/A'}</span>;
			}),
		},
		{
			field: 'listings.position_title',
			value: 'Job',
			headerName: 'Job',
			sortable: false,
			resizable: true,
			width: 250,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!node.id) {
					return <p style={{ fontStyle: 'italic', opacity: 0.6 }}>Loading...</p>;
				}
				if (!job || !node) {
					return null;
				}

				return (
					<>
						<div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<NavLink
									to={`/job/${job.id}`}
									className="color-inherit"
									target="_blank"
									style={{ display: 'block', minWidth: 'max-content' }}
								>
									{job.position_title}
								</NavLink>
								<div style={{ marginLeft: 10 }}>
									<Icons.Share style={{ width: 14, color: job?.is_sharable ? '#00bb00' : '#ff2222' }} />
								</div>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'specialties.name',
			headerName: 'Specialty',
			sortable: false,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return (
					<>
						{Utility.getItemLabel(data.specialties, job.specialty_id) || Utility.na}
						<br />
						<span className="color-muted">
							{Utility.getItemLabel(data.categories, job.professional_category_id) || null}
						</span>
					</>
				);
			},
		},
		{
			field: 'owner',
			headerName: 'Owner',
			sortable: true,
			resizable: true,
			width: 200,

			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return (
					<>
						<span style={{ display: 'block', minWidth: 150 }}>
							{job?.owner
								? data.owners.find(x => x.id === job?.owner || x.vendor_owner_id === job?.owner)?.name || 'Unknown'
								: Utility.na}
						</span>
					</>
				);
			},
		},
		{
			field: 'contact',
			headerName: 'Contact',
			sortable: false,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				const contact = job?.contacts?.[0];
				const nameObj = contact?.user || contact;
				const contactName = `${nameObj?.first_name || ''} ${nameObj?.last_name || ''}`.trim();

				return (
					<>
						<span style={{ display: 'block', minWidth: 150 }}>{contactName}</span>
						{job?.contacts?.[0]?.user?.claimed_at ? (
							<span className="color-muted">Account: {Utility.formatDate(job?.contacts?.[0]?.user?.claimed_at)}</span>
						) : null}
					</>
				);
			},
		},
		{
			field: 'listings.company',
			headerName: 'Company',
			sortable: false,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return (
					<>
						<span style={{ display: 'block', minWidth: 150 }}>{job?.company || Utility.na}</span>
						<span className="color-muted">
							{Utility.getItemName(data.owners, job.owner, 'vendor_owner_id') || null}
						</span>
					</>
				);
			},
		},
		{
			field: 'listings.city',
			headerName: 'Work Location',
			sortable: false,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return (
					<>
						<span className="color-muted">
							{job.state
								? `${job.city}, ${Utility.getFromObj(
										Utility.getIDFromObj(data.states, job.state, 'name'),
										'abbreviation',
								  )}`
								: Utility.na}
						</span>
						{/* {job?.office_locations?.length ? (
						<span className="color-muted">
							Offices: {job?.office_locations?.map(x => `${x?.city}, ${x?.abbreviation}`)?.join(', ')}
						</span>
					) : null}
					*/}
						{job?.work_location_preferences?.length ? (
							// <span>{job?.work_location_preferences?.map(x => x.label).join(', ')}</span>
							<span>
								{Utility.getConsolidatedLocationPreferences(job?.work_location_preferences)?.join(', ') || ''}
							</span>
						) : null}
					</>
				);
			},
		},
		{
			field: 'hero_skills',
			headerName: 'Hero Skills',
			sortable: true,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return (
					<>
						{job?.listing_skills
							?.filter(s => s.hero_skill)
							.map((s, i, a) => (
								<span key={s}>
									<Tooltip
										className="tooltip"
										title={`${s?.experience_years || 0} Year${s?.experience_years !== 1 ? 's' : ''}`}
										placement="top"
									>
										<span>{s?.skill?.label}</span>
									</Tooltip>
									{i < a.length - 1 ? ', ' : null}
								</span>
							))}
					</>
				);
			},
		},
		{
			field: 'listings.last_profile_update',
			headerName: 'Last Updated',
			sortable: true,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return (
					<>
						{job?.last_profile_update ? Utility.formatDate(moment(job?.last_profile_update)) : ''}
						<br />
						<span className="color-muted">{Utility.formatTime(job?.last_profile_update)}</span>
					</>
				);
			},
		},
		{
			field: 'listings.created_at',
			headerName: 'Created At',
			sortable: true,
			resizable: true,
			width: 200,
			sort: 'desc',
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return (
					<>
						{job?.created_at ? Utility.formatDate(moment(job?.created_at)) : ''}
						<br />
						<span className="color-muted">{Utility.formatTime(job?.created_at)}</span>
					</>
				);
			},
		},
		{
			field: 'listings.assessment_completed_at',
			headerName: 'Intake Completed',
			sortable: true,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return (
					<>
						{job?.assessment_completed_at ? Utility.formatDate(moment(job?.assessment_completed_at)) : ''}
						<br />
						<span className="color-muted">{Utility.formatTime(job?.assessment_completed_at)}</span>
					</>
				);
			},
		},
		{
			field: 'status_id',
			headerName: 'Job Status',
			sortable: true,
			resizable: true,

			width: 200,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return (
					<Chip
						size="small"
						label={Utility.getItemName(data.jobStatuses, job?.status_id, 'id')}
						className={`color-${Utility.getListingStatusColor(
							Utility.getItemName(data.jobStatuses, job?.status_id, 'id'),
						)}`}
					/>
				);
			},
		},
		{
			field: 'shared_match_count',
			headerName: 'Shared Matches',
			sortable: true,
			resizable: true,
			width: 125,
			cellRenderer: ({ data: job, node }, ref) => {
				if (!job || !node) {
					return null;
				}

				return <span style={{ display: 'block' }}>{job.shared_match_count || 0}</span>;
			},
		},

		// if (process.env.REACT_APP_FEATURE_MATCHING) {
		// 	jobRow.push(
		// 		<>
		// 			<NavLink to={`/job/${job.id}/matches`}>
		// 				<Button
		// 					variant="outlined"
		// 					size="small"
		// 					style={{
		// 						whiteSpace: 'nowrap',
		// 						marginLeft: 0,
		// 						paddingRight: 8,
		// 						paddingLeft: 8,
		// 					}}
		// 					color="primary"
		// 				>
		// 					<Icons.Search style={{ marginRight: 5 }} /> View Matches
		// 				</Button>
		// 			</NavLink>
		// 		</>,
		// 	);
		// }
	];

	const [clearMenuAnchorEl, setClearMenuAnchorEl] = React.useState(null);
	const [tableKey, setTableKey] = React.useState(0);

	const handleClearMenuClick = event => {
		setClearMenuAnchorEl(event.currentTarget);
	};

	const handleClearMenuClose = () => {
		setClearMenuAnchorEl(null);
	};

	const handleClearSort = () => {
		// window.localStorage.setItem('jobs-table', null);
		setTableKey(x => x + 1);
		handleClearMenuClose();
	};
	const handleClearFilter = () => {
		handleClearMenuClose();
		setFilter({ ...filter, filters: {} });

		if (gridApi) {
			// setGridKey(x => x + 1);
			gridApi.onFilterChanged();
		}
	};
	const handleClearAll = () => {
		handleClearMenuClose();
		handleClearSort();
		setTimeout(() => {
			handleClearFilter();
		}, 250);
	};

	const refreshTable = () => {
		setGridKey(x => x + 1);
	};

	return (
		<Page filterPadding={filter.isOpen}>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Jobs</Typography>
				</PageHeader.Left>

				{/* <PageHeader.Middle>
					<TextField
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icons.Search style={{ color: 'var(--primaryColor)' }} />
								</InputAdornment>
							),
						}}
						fullWidth
					/>
				</PageHeader.Middle> */}

				<PageHeader.Right>
					{selectedItems.length ? (
						<NavLink to={`/jobs/details?jobs=${selectedItems?.join(',')}`} className="color-inherit">
							<Button
								variant="contained"
								color="primary"
								// onClick={openSelectedItemOverview}
								startIcon={<Icons.Fullscreen />}
							>
								Open Overview ({selectedItems.length})
							</Button>
						</NavLink>
					) : null}
					<Button
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleClearMenuClick}
						variant="outlined"
						color="primary"
						endIcon={<Icons.ArrowDropDown />}
					>
						Clear
					</Button>
					<Menu
						id="clear-menu"
						anchorEl={clearMenuAnchorEl}
						keepMounted
						open={Boolean(clearMenuAnchorEl)}
						onClose={handleClearMenuClose}
					>
						<MenuItem onClick={handleClearSort}>Clear Sort</MenuItem>
						<MenuItem onClick={handleClearFilter} disabled={!Object.keys(filter?.filters || {}).length}>
							Clear Filter
						</MenuItem>
						<MenuItem onClick={handleClearAll}>Clear All</MenuItem>
					</Menu>
					<Tooltip className="tooltip" title={`${numFilters} Filter${numFilters > 1 ? 's' : ''}`} placement="top">
						<Button
							style={{
								backgroundColor: numFilters > 0 ? 'rgba(76, 159, 255,.3)' : 'transparent',
							}}
							variant="outlined"
							color="primary"
							onClick={filter.isOpen ? closeFilter : openFilter}
							startIcon={<Icons.FilterList />}
						>
							Filter
						</Button>
					</Tooltip>

					{/* <Button
						variant="text"
						color="primary"
						onClick={ev => Utility.notImplemented(store)}
						startIcon={<Icons.GetApp />}
					>
						Export to XLS
					</Button> */}

					<Button
						variant="contained"
						color="primary"
						onClick={() =>
							store.dispatch(SiteActions.showModal(<JobSyncModal category={menu.category} onSync={refreshTable} />))
						}
						startIcon={<Icons.Sync />}
					>
						Sync Job
					</Button>
				</PageHeader.Right>
			</PageHeader.Header>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					paddingBottom: 15,
					marginTop: -20,
				}}
			>
				<Checkbox checked={!!filterIntakeCompleted} onChange={ev => setFilterIntakeCompleted(ev.target.checked)} />
				<Typography variant="body2" style={{ marginBottom: 0 }}>
					Intake Completed
				</Typography>
			</div>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<JobFilters
				enableSavedFilters
				savedFilters={savedFilters}
				setSavedFilters={setSavedFilters}
				activeSaveFilterId={activeSaveFilterId}
				setActiveSaveFilterId={setActiveSaveFilterId}
				onClose={closeFilter}
				onReset={() => setFilter({ ...filter, filters: {} })}
				data={data}
				updateFilters={updateFilters}
				filter={filter}
				setFilter={setFilter}
				displayFilters={[
					'owner',
					'company',
					'contact',
					'position_title',
					'vendor_job_id',
					'is_open',
					'status_id',
					'professional_experience_years_range',
					'salary_range',
					'city',
					'state',
					'approved_remote_locations',
					'description',
					'professional_category_id',
					'specialty_id',
					'skills',
					'hero_skills',
					'industries',
					'offer_relocation',
					'work_visa_type_id',
					'recent_leadership_role_id',
					'recent_leadership_years_range',
					'role_type',
					'last_profile_update_range',
					'assessment_completed_range',
				]}
			/>

			<div className="ag-theme-balham" style={{ height: 'calc(100% - 120px)', width: '100%' }}>
				{dataIsLoaded ? (
					<AgGridReact
						key={gridKey}
						onGridReady={({ api }) => {
							setGridApi(api);
							// api.sizeColumnsToFit();
						}}
						rowModelType="infinite"
						datasource={datasource}
						rowHeight={60}
						// pagination
						// paginationPageSize={15}
						// loading
						loadingCellRenderer={() => {
							console.log('Loading...');
							return <div>Loading...</div>;
						}}
						loadingCellRendererParams={{}}
						cacheBlockSize={15}
						ref={gridRef} // Ref for accessing Grid's API
						// rowData={tableData} // Row Data for Rows
						columnDefs={columnDefs} // Column Defs for Columns
						// defaultColDef={defaultColDef} // Default Column Properties
						animateRows // Optional - set to 'true' to have rows animate when sorted
						rowSelection="multiple" // Options - allows click selection of rows
						onCellClicked={() => {}} // Optional - registering for Grid Event
						onSelectionChanged={({ api }) => {
							const selectedRows = api.getSelectedRows();
							setSelectedItems(selectedRows.map(x => x.id));
						}}
					/>
				) : null}
			</div>

			{/* <Table
				stickyStateKey="jobs-table-v9"
				onUpdate={ListingsAPI.getListings}
				filters={filter.filters}
				format={formatData}
				defaultOrderBy="listings.created_at"
				defaultSortDirection="desc"
				headers={headers}
				ref={table}
				// key={tableKey}
				resetStateKey={tableKey}
			/> */}

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/job/${menu.listing.id}`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.WorkOutline color="primary" />
						</ListItemIcon>
						View Job
					</MenuItem>
				</NavLink>

				<NavLink to={`/job/${menu.listing.id}/matches`}>
					<MenuItem>
						<ListItemIcon>
							<Icons.Search color="primary" />
						</ListItemIcon>
						View Suggested Matches
					</MenuItem>
				</NavLink>
				{/* <NavLink to={`/job/${menu.listing.id}/match-testing`}>
					<MenuItem>
						<ListItemIcon>
							<Icons.TrendingUp color="primary" />
						</ListItemIcon>
						Testing
					</MenuItem>
				</NavLink> */}
				{/* <NavLink to={`/job/${menu.listing.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit Job
					</MenuItem>
				</NavLink> */}
				<MenuItem disabled={menu.listing?.contacts?.[0]?.user?.claimed_at} onClick={sendAssessment}>
					<ListItemIcon>
						<Icons.Send color="primary" />
					</ListItemIcon>
					Portal Invitation
				</MenuItem>
				<MenuItem
					disabled={
						!menu.listing?.contacts?.[0]?.user?.claimed_at ||
						menu.listing?.bypass_intake ||
						menu.listing?.assessment_completed_at
					}
					onClick={sendAssessment}
				>
					<ListItemIcon>
						<Icons.Send color="primary" />
					</ListItemIcon>
					Send Job Notification
				</MenuItem>
				{/* <MenuItem  onClick={()=>{
					console.log("Sending job notification...")
				}}>
					<ListItemIcon>
						<Icons.Send color="primary" />
					</ListItemIcon>
					Send Job Notification
				</MenuItem> */}
				{/* <MenuItem onClick={mockAssessment}>
					<ListItemIcon>
						<Icons.Assignment color="primary" />
					</ListItemIcon>
					Mock Assessment
				</MenuItem> */}
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						store.dispatch(SiteActions.showModal(<JobDeleteModal job={menu.listing} onDelete={refreshTable} />));
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete Job
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default Jobs;
